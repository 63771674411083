.spinner48 {
    font-size: 48px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.magnifier {
    font-size: 20px;
    background-color: white;
    padding: 2px;
}
